var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "流程进度", name: "first" } },
            [
              _vm.activeName == "first"
                ? _c("wf-flow", { attrs: { flow: _vm.flow } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "流程图", name: "second" } },
            [
              _vm.activeName == "second"
                ? _c("wf-design", {
                    ref: "bpmn",
                    staticStyle: { height: "60vh" },
                    attrs: { options: _vm.bpmnOption },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }